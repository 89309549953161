<template>
  <v-responsive>
    <SubMenuCustomerMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuCustomerMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuCustomer></SubMenuCustomer>
        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>




          <v-card class="d-flex justify-center mt-10 mb-5" flat
            to="/join">
            <v-img
              src="@/assets/join_banner.png"
            ></v-img>
          </v-card>





          <v-card
            style="margin:  auto; border: 1px solid #eee"
            flat
          >
            <v-list two-line>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">01</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>매주 20조합 로또 분석 번호 제공!</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                      회원 가입만으로도 빅데이터 기반의 로또1등 당첨 번호를 매주 제공해드립니다.
(추가 발급 및 SMS수령은 포인트 차감)

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>



              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">02</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>로또 당첨번호 알림 실시간 제공!</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
매주 토요일 8시 45분, SBS 로또 추첨 생방송 직후 실시간으로 핸드폰 문자메세지로 발송해 드립니다.
(추첨방송 직후 당첨번호 실시간 업데이트)

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>



              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">03</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>마이로또 무제한 저장 서비스!</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">
                      분석 조합 및 조합기 추출 조합을 무제한으로 나의 조합 관리 페이지에 저장 가능합니다.

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">04</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>최신 개발된 로또 조합 시스템 서비스</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">빅데이터 기반 로또 1등 당첨 조합기와 고정수 기반 인공지능 완전수 조합 추출 시스템을 제공합니다.
(추가 발급 및 SMS 수령은 포인트 차감)

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">05</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>로또분석 기존 당첨 번호 분석기 무제한 서비스!</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">기존 로또 당첨번호를 분석한 고유의 분석 자료를 무제한 제공해 드립니다.

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">06</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>행운번호 완전조합기 제공</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">나의 로또 행운번호 완전조합기를 제공해드립니다.

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <strong style="font-size:26px;font-weight:bold;color:#0D47A1;font-family:verdana;">07</strong>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="white-space:normal;font-size:16px;font-weight:bold;"><strong>로또 전문가와의 소통과 커뮤니티 참여 자격 부여</strong></v-list-item-title>
                    <v-list-item-subtitle style="white-space:normal;font-size:13px;">로또 조합 관련 자유토론 방과 로또 명인/전문가와의 소통의 장을 통해 당첨 노하우를 전수 받아 보세요!
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>








            </v-list>
          </v-card>





        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "About",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "고객센터" },
      { text: "서비스 안내" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),
};
</script>
<style></style>
